<style scoped></style>
<template>
  <div class="home" style=""></div>
</template>

<script>
/* eslint-disable */

export default {
  name: "Home",
  data() {
    return {};
  },

  components: {},
  methods: {},
  created() {
    this.$router.push("/answer-list");
  },
};
</script>
<style></style>
